<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card-group deck>
			<b-card style="border-radius: 0">
				<b-row>
					<b-col>
						<h4 class="mb-1">
							{{ translate('cc_information') }}
						</h4>
					</b-col>
				</b-row>
				<hr class="mt-2 mb-4">
				<div>
					<b-row class="px-3">
						<div
							:class="{'px-0': !['xs'].includes(windowWidth) && (!addCreditCard && creditCardsData.length) }"
							class="col-12 address-list">
							<credit-card-grid
								v-if="!addCreditCard"
								:loading="creditCardsLoading"
								:data="creditCardsData"
								:show-select="true"
								:show-default="false"
								:show-disclaimer="false"
								:selected="computedCardId"
								@change="$emit('onUpdate', $event)" />
							<div
								v-if="!addCreditCard && creditCardsData.length && !creditCardsLoading"
								class="row">
								<div class="col-12">
									<hr class="mt-0">
								</div>
							</div>
							<template v-if="(addCreditCard || !creditCardsData.length) && !creditCardsLoading">
								<div class="row mb-2">
									<div
										:class="{'px-0': !['xs'].includes(windowWidth) }"
										class="col-12">
										<template v-for="(item, index) in displayedAllowedCC">
											<img
												:key="index"
												:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
												alt="Credit Card"
												align="right"
												class="text-right ml-2 resize-img">
										</template>
									</div>
								</div>
								<new-credit-card
									ref="newCreditCardView"
									:borderless="true"
									:show-buttons="false"
									:include-title="false"
									:show-disclaimer="true"
									:show-default-switch="false"
									new-address-btn-variant="link"
									new-address-btn-class="pl-2 py-0"
									no-body
									@cardCreated="cardCreated">
									<template
										v-if="addCreditCard && creditCardsData.length > 0"
										v-slot:middle>
										<button
											:class="{ 'w-100' : ['xs', 'sm'].includes(windowWidth) }"
											class="btn btn-link b-4 float-left px-2 pt-0 pb-4"
											@click="cardFormState">
											{{ translate('see_credit_card_list') }}
										</button>
									</template>
								</new-credit-card>
							</template>
						</div>
					</b-row>
				</div>
				<b-row>
					<div
						v-if="!addCreditCard && !creditCardsLoading"
						class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
						<button
							:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
							class="btn btn-link b-4 float-left p-0"
							@click="cardFormState">
							{{ translate('use_another_credit_card') }}
						</button>
					</div>
				</b-row>
				<b-row
					v-if="addCreditCard"
					no-gutters
					class="justify-content-end">
					<b-col
						:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
						md="auto">
						<b-button
							style="min-width: 150px"
							class="w-100"
							variant="light"
							size="lg"
							@click="cardFormState">
							{{ translate('cancel') }}
						</b-button>
					</b-col>
					<b-col
						:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
						md="auto">
						<b-button
							style="min-width: 200px"
							class="w-100"
							variant="primary"
							size="lg"
							@click="newCreditCard()">
							{{ translate('save_credit_card_button') }}
						</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-card-group>
	</div>
</template>

<script>
import { PaymentMethods, Profile, PurchasePayment } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import CreditCard from '@/util/CreditCard';
import CreditCardGrid from '@/views/Profile/CreditCard/components/CreditCardGrid.vue';
import NewCreditCard from '@/views/Profile/CreditCard/components/NewCreditCard.vue';
import { ALLOWEDCC_BY_COUNTRY } from '@/settings/CreditCard';
import CreditCardFormV2 from '@/mixins/CreditCardFormV2';
import EventBus from '@/util/eventBus';

export default {
	name: 'NewDefaultCreditCard',
	messages: [PaymentMethods, PurchasePayment, Profile],
	components: {
		CreditCardGrid,
		NewCreditCard,
	},
	mixins: [CreditCardFormV2, WindowSizes],
	props: {
		cardId: {
			type: [String, Number],
			default: 0,
		},
		validationErrors: {
			type: Object,
			default: () => ({}),
		},
		lastShippingId: {
			type: [String, Number],
			default: 0,
		},
	},
	data() {
		return {
			creditCards: new CreditCard(),
			addCreditCard: false,
		};
	},
	computed: {
		hasCreditCardsData() {
			const response = this.creditCardsData.length;
			return !!response;
		},
		creditCardsData() {
			try {
				const { data } = this.creditCards.data.response.data;
				if (data === undefined) {
					return [];
				}
				return data;
			} catch (error) {
				return [];
			}
		},
		creditCardsLoading() {
			return !!this.creditCards.data.loading;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
		computedCardId() {
			return Number(this.cardId);
		},
	},
	watch: {
		addCreditCard(addCreditCard) {
			this.$emit('addCreditCard', addCreditCard);
		},
		creditCardsLoading(loading) {
			this.$emit('loading', loading);
		},
		lastShippingId() {
			EventBus.$emit('reloadCardAddresses');
		},
	},
	mounted() {
		this.getCreditCardsData();
	},
	methods: {
		getCreditCardsData(setCardId = true) {
			return this.creditCards.getCreditCards(this.$user.details().id).then((response) => {
				if (setCardId && response.length) {
					if (!response.some((card) => Number(card.id) === Number(this.cardId))) {
						this.$emit('onUpdate', Number(response[0].id));
					}
				} else {
					this.addCreditCard = true;
				}
			});
		},
		cardFormState() {
			this.addCreditCard = !this.addCreditCard;
		},
		async cardCreated(cardId) {
			this.$emit('onUpdate', cardId);
			this.getCreditCardsData(false)
				.then(() => this.$emit('cardCreated'))
				.finally(() => { this.addCreditCard = false; });
		},
		newCreditCard() {
			if (this.addCreditCard) {
				this.$refs.newCreditCardView.newCreditCard();
			}
		},
	},
};
</script>
