<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card-group deck>
			<b-card>
				<!-- Section title -->
				<b-row>
					<b-col>
						<h4 class="mb-1">
							{{ translate('autoship_configurations') }}
						</h4>
					</b-col>
				</b-row>
				<hr class="mt-2 mb-4">
				<!-- Configurations -->
				<b-row
					v-if="!loadingProducts"
					class="flex">
					<!-- Choose produts -->
					<b-col
						sm="12"
						md="5">
						<b-card
							class="mx-0 h-100 d-flex"
							:body-class="{
								'd-flex': Object.keys(finalSelected).length == 0,
								'justify-content-center align-items-center': Object.keys(finalSelected).length == 0,
								'align-items-end': Object.keys(finalSelected).length == 0,
							}">
							<!-- Button to choose -->
							<div class="row no-gutters">
								<div
									:class="{
										'py-2': ['xs', 'sm'].includes(windowWidth),
									}"
									class="col-12 col-md text-center">
									<button
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100 my-2' : ''"
										class="btn btn-success"
										@click="openAutoshipModal = true">
										{{ translate('choose_monthly_autoship') }}
									</button>
								</div>
							</div>
							<!-- Products list -->
							<div class="row mt-3 mb-2">
								<div class="col-auto">
									<selected-products-overview :products="displayedProducts" />
								</div>
							</div>
							<!-- Errors lists -->
							<template v-if="(typeof errors['products'] !== 'undefined')">
								<b-alert
									class="mt-3"
									variant="warning"
									show>
									<span
										v-for="error in errors['products']"
										:key="error">
										<i class="fa fa-exclamation-circle" />
										{{ error }} <br>
									</span>
								</b-alert>
							</template>
						</b-card>
					</b-col>
					<!-- Date and periodicity settings -->
					<b-col
						md="7"
						sm="12">
						<b-card
							class="mx-0 p-0"
							body-class="p-2">
							<b-col
								sm="12"
								md="12">
								<div
									class="form-group fadeIn animated  mt-2">
									<label
										for="nextAutoshipDate"
										class="label"><b>{{ translate('set_autoship_date') }}</b></label>
									<b-row
										no-gutters
										class="align-items-start">
										<b-col
											lg="6"
											md="6">
											<div>
												<b-datepicker
													id="nextAutoshipDate"
													v-model="nextAutoshipDate"
													:input-props="{ class: 'form-control' }"
													:formats="calendarConfig"
													name="nextAutoshipDate"
													show-caps
													@input="clearError('next_autoship_date')" />
												<template v-if="hasError('next_autoship_date')">
													<ul class="pl-3 mb-0">
														<li
															v-for="error in errors['next_autoship_date']"
															:key="error"
															class="custom-invalid-feedback animated fadeIn"
															v-text="error" />
													</ul>
												</template>
											</div>
										</b-col>
									</b-row>
								</div>
							</b-col>
							<template v-if="isNewGen">
								<b-col
									sm="12"
									md="12">
									<div
										class="form-group fadeIn animated mt-2">
										<label
											for="nextAutoshipDate"
											class="label"><b>{{ translate('autoship_periodicity') }}</b></label>
										<b-row
											no-gutters
											class="align-items-start">
											<b-col
												lg="6"
												md="6">
												<div>
													<select
														id="autoship_periodicity"
														v-model="autoshipPeriodicity"
														name="autoship_periodicity"
														class="form-control">
														<option
															v-for="configuration in availablePeriodicity()"
															:key="configuration.code_name"
															:value="configuration.value">
															{{ translate(configuration.code_name) }}
														</option>
													</select>
													<template v-if="hasError('autoship_periodicity')">
														<ul class="pl-3 mb-0">
															<li
																v-for="error in errors['autoship_periodicity']"
																:key="error"
																class="custom-invalid-feedback animated fadeIn"
																v-text="error" />
														</ul>
													</template>
												</div>
											</b-col>
										</b-row>
										<b-row
											v-if="isNewGen"
											class="mt-3">
											<b-col>
												<span
													v-html="newGenConfigurationInfo" />
											</b-col>
										</b-row>
									</div>
								</b-col>
							</template>
						</b-card>
					</b-col>
				</b-row>
				<div
					v-if="loadingProducts"
					class="col-auto text-center mt-2">
					<is-loading
						:loading="loadingProducts"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
			</b-card>
		</b-card-group>
		<add-autoship-modal
			v-show="!loadingProducts"
			:loading-products="loadingProducts"
			:autoship-products="autoshipProducts"
			:products="products"
			:open="openAutoshipModal"
			:title="translate('choose_monthly_autoship')"
			@cancel="handleCancel"
			@confirm="handleConfirm" />
	</div>
</template>
<script>
import AddAutoshipModal from '@/components/AddAutoshipModal';
import SelectedProductsOverview from '@/components/SelectedProductsOverview';
import { SIMPLE_PRODUCTS_PARENT_PLACEHOLDER, SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import Products from '@/util/Products';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import { Purchase, AutoshipPeriodicity, AutoshipInformation } from '@/translations';
import IsLoading from '@/components/Loading';
import { NEW_GEN_CONFIGURATIONS, OLD_GEN_COUNTRIES } from '@/settings/Autoship';
import {
	// MDY_FORMAT,
	// YMD_FORMAT,
	// YMDHMS_FORMAT,
	V_CALENDAR_CONFIG as calendarConfig,
} from '@/settings/Dates';

export default {
	name: 'AutoshipProductsChoose',
	messages: [Purchase, AutoshipPeriodicity, AutoshipInformation],
	components: {
		AddAutoshipModal,
		SelectedProductsOverview,
		IsLoading,
	},
	mixins: [ConfigurableProducts, WindowSizes, EarlyLaunch],
	props: {
		errors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			// countriesListForSignature,
			autoshipProducts: {},
			showDateConfigs: false,
			openAutoshipModal: false,
			prodData: new Products(),
			oldGenCountries: OLD_GEN_COUNTRIES,
			autoshipConfigurations: NEW_GEN_CONFIGURATIONS,
			autoshipPeriodicity: null,
			nextAutoshipDate: null,
			calendarConfig,
			finalSelected: {},
		};
	},
	computed: {
		products() {
			try {
				const productsData = this.prodData.data.response.data.data
					.filter((product) => !!product.attributes.available_for_autoship)
					.sort((a, b) => {
						const aIndex = sortList.indexOf(a.attributes.sku);
						const bIndex = sortList.indexOf(b.attributes.sku);

						if (aIndex === -1) return 1;
						if (bIndex === -1) return -1;

						return aIndex - bIndex;
					});

				return productsData;
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		savedDiscount() {
			const { discount } = { ...this.getStepInformation('RegisterShipping') };
			return discount;
		},
		savedShipping() {
			const { shipping } = { ...this.getStepInformation('RegisterShipping') };
			return shipping;
		},
		displayedProducts() {
			return Object.entries(this.autoshipProducts).reduce((accumulator, [sku, item]) => {
				const parentCodeName = item.parent_code_name || SIMPLE_PRODUCTS_PARENT_PLACEHOLDER;

				if (item.value !== 0) {
					accumulator[parentCodeName] = {
						...accumulator[parentCodeName],
						[item.code_name]: {
							qty: item.value,
							sku,
						},
					};
				}

				return accumulator;
			}, {});
		},
		isNewGen() {
			try {
				return !this.oldGenCountries.includes(this.$user.details().country.iso_code_2);
			} catch (error) {
				return true;
			}
		},
		newGenConfigurationInfo() {
			const selectedPeriodicity = this.getPeriodicityData(this.autoshipPeriodicity);
			return this.translate(`customer_${selectedPeriodicity.code_name}_description`);
		},
	},
	watch: {
		autoshipProducts(value) {
			this.finalSelected = {};
			let displayConfigs = false;
			// this.createWatchesForProducts(value);
			Object.keys(value).forEach((item) => {
				const qty = value[item].value;
				if (qty > 0) {
					this.finalSelected[item] = qty;
					displayConfigs = true;
				}
			});
			this.showDateConfigs = displayConfigs;
			this.$emit('updateProducts', this.finalSelected);
		},
		autoshipPeriodicity(value) {
			this.$emit('updatePeriodicity', value);
		},
		nextAutoshipDate(value) {
			this.$emit('updateDate', value);
		},
	},
	mounted() {
		this.initializeAutoship();
		this.autoshipPeriodicity = this.availablePeriodicity()[0].value;
	},
	methods: {
		handleConfirm(selectedProducts) {
			this.openAutoshipModal = false;
			this.autoshipProducts = { ...selectedProducts };
		},
		handleCancel() {
			this.openAutoshipModal = false;
		},
		availablePeriodicity() {
			return this.autoshipConfigurations.filter((item) => item.code_name !== 'max');
		},
		getPeriodicityData(periodicityValue) {
			try {
				return this.autoshipConfigurations.find((element) => element.value === periodicityValue);
			} catch (e) {
				return [];
			}
		},
		initializeAutoship() {
			this.prodData.getProducts(this.country, undefined, { autoship: true, formatAutoship: true }).then(() => {
				// Flatten configurable products
				const products = this.products.reduce((accumulator, product) => {
					if (!product.attributes.has_configurations) {
						accumulator.push({
							sku: product.attributes.sku,
							code_name: product.attributes.code_name,
							parent_sku: null,
							parent_code_name: null,
						});
					} else {
						Object.keys(this.getProductConfigs(product)).forEach((config) => {
							const configuredProduct = this.getConfiguredProduct(product, config);

							accumulator.push({
								sku: configuredProduct.sku,
								code_name: configuredProduct.code_name,
								parent_sku: product.attributes.sku,
								parent_code_name: product.attributes.code_name,
							});
						});
					}

					return accumulator;
				}, []);

				// Load saved autoship products or fill in products equally
				// until reaching AUTOSHIP_MIN_QTY of total autoship product count
				for (let index = 0; index < products.length; index += 1) {
					const product = products[index];
					const { sku } = product;
					const currentAutoshipProduct = this.autoshipProducts[sku];

					this.$set(this.autoshipProducts, sku, {
						...product,
						value: currentAutoshipProduct ? currentAutoshipProduct.value += 0 : 0,
						required: currentAutoshipProduct ? currentAutoshipProduct.required : false,
					});
				}
			});
			return null;
		},
		createWatchesForProducts(value) {
			Object.keys(value).forEach((item) => {
				this.$watch(() => this.autoshipProducts[item].required, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal) {
						if (this.autoshipProducts[item].value === 0) {
							this.autoshipProducts[item].value = 1;
						}
					} else {
						this.autoshipProducts[item].value = 0;
					}
				});
				this.$watch(() => this.autoshipProducts[item].value, (newVal) => {
					this.clearValidationError(`${this.currentValidation}.autoship.products`);
					if (newVal > 0) {
						this.autoshipProducts[item].required = true;
					} else {
						this.autoshipProducts[item].required = false;
					}
				});
			});
		},
		clearError(error) {
			this.errors[error] = undefined;
		},
		hasError(error) {
			return this.errors[error];
		},
	},
};
</script>
